import jstDeDE from './jsonToForm/jtf-de-DE'
import commonDeDE from './common/de-DE'

export default {
  general: {
    appName: "synQup",
    cancel: "Abbrechen",
    close: "Schließen",
    delete: "Löschen",
    save: "Speichern",
    apply: "Übernehmen",
    applied: "Änderungen übernommen",
    confirm: "Bestätigen",
    clear: "Löschen",
    yes: "Ja",
    no: "Nein",
    and: "und",
    or: "oder",
    of: "von",
    loading: "Lade…",
    time: {
      momentAgo: "vor einem Moment",
      difference: "Zeitunterschied: {time}",
      timeAgo: "vor {timeData}",
      invalid: "Fehlerhaftes Datum",
      singular: {
        secondsAgo: "vor {time} Sekunde",
        minutesAgo: "vor {time} Minute",
        hoursAgo: "vor {time} Stunde",
        daysAgo: "vor {time} Tag",
        weeksAgo: "vor {time} Woche",
        monthsAgo: "vor {time} Monat",
        yearsAgo: "vor {time} Jahre",
      },
      plural: {
        secondsAgo: "vor {time} Sekunden",
        minutesAgo: "vor {time} Minuten",
        hoursAgo: "vor {time} Stunden",
        daysAgo: "vor {time} Tagen",
        weeksAgo: "vor {time} Wochen",
        monthsAgo: "vor {time} Monaten",
        yearsAgo: "vor {time} Jahren",
      },
      short: {
        "milliseconds": "ms",
        "seconds": "s",
        "minutes": "m",
        "hours": "h",
        "days": "d",
      },
      long: {
        "milliseconds": " msek",
        "seconds": " sek",
        "minutes": " min",
        "hours": " std",
        "days": " Tag(e)",
      }
    },
    hotkeys: {
      save: "STRG + s"
    },
    errorModal: {
      unknownErrorHeadline: "Unbekannter Fehler",
    },
    logout: 'Logout',
    hidePassword: "Passwort verbergen",
    showPassword: "Passwort anzeigen",
    itemsPerPage: "Items je Seite",
    invalidJson: "Fehlerhaftes JSON",
    invalidSchema: "Fehlerhaftes Schema. Bitte editieren Sie nichts innerhalb des \'_id'\-Blocks",
    expandAll: "Alle Knoten öffnen",
    collapseAll: "Alle Knoten schließen",
    copyEditorContent: 'Editor-Inhalt kopieren',
    clearEditor: 'Editor leeren',
    resetEditor: 'Editor zurücksetzen',
    noValidData: 'Keine gültigen Daten',
    prettify: 'Code verschönern (Shortcut: STRG + ALT + P im aktiven Editor)',
    readOnly: 'Editor-Inhalt ist schreibgeschützt'
  },
  header: {
    preferences: "Optionen",
    logout: "Logout",
    menuToggle: "Menü"
  },
  footer: {
    copyright: "© {year} synQup",
    build: "Build: {build}",
    imprint: "Impressum",
    imprintLink: "https://www.synqup.com/de/impressum/"
  },
  menu: {
    selectLanguage: "Sprache wählen",
    langs: {
      enUs: "Englisch",
      deDe: "Deutsch"
    },
    routes: {
      login: "Login",
      dashboard: "Dashboard",
      flows: "Flows",
      flowExecutions: "Flow Ausführungen",
      entities: "Entitäten",
      cronjobs: "Cronjobs",
      filebrowser: "Dateibrowser",
      logs: "Logs",
      users: "Nutzer",
      validationInfo: 'Validierungsinfo',
      validationStats: 'Validierungsstatistiken',
      validation: 'Validierung',
      cacheUsage: 'Cache-Nutzung',
      failedMessages: 'Gescheiterte Queue-Nachrichten',
      formBuilder: 'Formularersteller',
      configSnippets: 'Konfigurations-Snippets'
    },
    preferences: "Einstellungen",
    help: "Hilfe benötigt?",
    helpLink: "https://www.synqup.com/de/documentation/"
  },
  publicPages: {
    login: {
      headline: "Bei synQup einloggen",
      desc: "",
      inputNameLabel: "E-Mail",
      inputPasswordLabel: "Passwort",
      rememberMe: "Login merken",
      passwordRecover: "Passwort vergessen",
      submit: "Login"
    },
    passwordRecover: {
      headline: "Passwort vergessen?",
      desc: "Fordern Sie ein neues Passwort über Ihre verknüpfte Mailadresse an. Diese enthält den Freischaltcode und Info zum weiteren Vorgehen",
      inputLabel: "Mail",
      submit: "Request new password",
      checkMails: "Check your mails",
      submitSending: "Sending password request...",
      pwConfirmFail: "Das Passwort stimmt nicht mit der vorherigen Eingabe überein",
      pwTooShort: "Passwort muss mindestens 8 Zeichen lang sein",
      requestFail: "Neues Passwort konnte nicht gesetzt werden. \nBitte kontaktieren Sie den Administrator, wenn das Problem bestehen bleibt.",
      requestSuccess: "Password erfolgreich zurückgesetzt."
    },
    passwordReset: {
      headline: "Passwort zurücksetzen",
      desc: "Bitte wählen Sie ein neues Passwort",
      user: "E-Mail Addresse",
      userTitle: "Bitte geben Sie Ihre Mailadress an",
      password: "Neues Passwort",
      passwordTitle: "Neues Passwort eingeben",
      passwordConfirm: "Neues Passwort wiederholen",
      passwordConfirmTitle: "Bitte wiederholen Sie das obige Passwort",
      submit: "Password zurücksetzen",
      requestSuccess: "Passwortwiederherstellung wurde angefordert. Bitte überprüfen Sie Ihre Mails.",
      requestFail: "Passwortwiederherstellungsmail konnte nicht gesendet werden. Ist die Mailadresse korrekt??\n"
    },
    contact: {
      headline: "Kontakt",
      desc: "Fragen? Feedback? Vorschläge? Schreiben Sie uns!"
    },
    impressum: {
      headline: "Impressum",
      desc: "Impressum desc hier"
    }
  },
  dashboard: {
    headline: "Dashboard",
    desc: `Wilkommen bei synQup`,
    card: {
      product: 'Produkte',
      category: 'Kategorien',
      customer: 'Kunden',
      order: 'Bestellungen'
    },
    messageQueue: {
      headline: "Message Queues",
      refresh: "Aktualisieren"
    },
    heartbeat: {
      headline: "Heartbeat",
      delay: "Letzter Heartbeat vor",
      delay2: "",
      noHeartbeat: "Es gab noch keinen Heartbeat",
      heartbeat: "Heartbeat",
      timestamp: "Aktuelle Zeit",
      gracePeriod: "Toleranz / Intervall",
      graceExceededInfo: "Die vergangene Zeit seit dem letzten Heartbeat ist größer als das spezifizierte Interval plus Toleranz. Das kann bedeuten, dass das Heartbeat-Command noch nicht gestartet wurde oder keine Worker laufen.",
      refresh: "Aktualisieren",
      start: "Starten",
      startSuccess: "Heartbeat erfolgreich gestartet. Sie können ihn jetzt aktualisieren.",
      fine: "Gut",
      warning: "Warnung",
      error: "Fehler"
    },
    supervisor: {
      headline: "Supervisor",
      restart: "Neu starten",
      refresh: "Aktualisieren",
      restartConfirm: "Sind Sie sicher, dass sie den Supervisor neu starten möchten?",
      restartSuccess: "Supervisor erfolgreich neu gestartet",
      restartDenied: "Sie haben nicht die Berechtigung, diese Aktion auszuführen",
      noData: "Keine Daten vorhanden",
      restartFailed: "Supervisor-Neustart gescheitert"
    },
    diskSpace: {
      headline: "Speicherplatz",
      freeSpace: "Freier Speicherplatz",
      usedSpace: "Benutzter Speicherplatz",
      refresh: "Aktualisieren",
    },
    redisMemory: {
      headline: "Redis-Speicherverbrauch",
      refresh: "Aktualisieren",
      loading: "Lade Daten…",
      noData: "Keine Daten"
    }
  },
  flow: {
    overview: {
      headline: "Flows",
      desc: `Hier können Sie Ihre aktuellen synQup Flows einsehen.\n
        Sie können Flows starten, rekonfigurieren oder neue anlegen.`,
      showMore: "Flow-Aktionen anzeigen",
      start: "Start",
      end: "Ende",
      view: "Details einsehen",
      addFlow: "Flow hinzufügen",
      renameFlow: "Flow umbenennen",
      deleteFlow: "Flow löschen",
      addJdm: "Job Dispatcher Mapping hinzufügen",
      importFlow: "Flows importieren",
      exportFlow: "Flows exportieren",
      exportFlowSingle: "Flow exportieren",
      noFlows: "Es gibt noch keine Flows. Beginnen Sie, indem Sie einen anlegen.",
      itemsPerPage: "Flows je Seite",
      modal: {
        namePlaceholder: "Name",
        nameRequired: "Flow Name benötigt",
        jsonConfigHeadline: "JSON Config",
        flowsImportText: "Flows Import Text",
        flowsExportText: "Flows Export Text",
        endFlow: "'{flowName}' beenden?",
        endFlowConfirm: "Diese Aktion kann zu unerwarteten Ergebnissen oder fehlerhaften Datenständen in angeschlossenen Systemen führen. Sind Sie sich sicher?",
        endFlowSuccess: "Flow beendet",
        addFlow: "Flow hinzufügen",
        importFlow: "Flows importieren",
        importButton: "Import",
        exportButton: "Download",
        importFile: "Datei importieren",
        exportFlow: "Flows exportieren",
        exportFile: "Datei exportieren",
        editFlow: "Flow anpassen",
        deleteFlow: "Flow löschen",
        deleteFlowConfirm: "Sobald Sie \'{flowName}\' löschen, werden alle damit verknüpften Unteritems und Konfigurationen ebenfalls gelöscht.",
        deleteFlowConfirm2: "Wenn Sie absolut sicher sind, bestätigen Sie dies bitte, indem Sie den Flownamen ins untere Feld eingeben.",
        deleteFlowConfirmInputLabel: "Flowname",
        deleteFlowConfirmInputTitle: "Bitte Flownamen eingeben: {flowName} (Groß- und Kleinschreibung unwichtig)",
        deleteFlowConfirmError: "Validation gescheitert",
        createFlow: "Flow anlegen",
        copySuccess: "Flow/s Export text kopiert!",
        copyFail: "Ein Fehler ist beim kopieren des Flow/s Export Textes aufgetreten!",
        exportSuccess: "Flow/s-Exportdatei wurde heruntergeladen!",
        exportFail: "Beim Download der the Flow-Exportdatei ist ein Fehler aufgetreten!",
        flowCreationSuccess: "Flow erstellt",
        flowCreationFailed: "Erstellung des Flows gescheitert",
        flowDeletionSuccess: "Flow gelöscht",
        flowDeletionFailed: "Löschen des Flows gescheitert",
        flowEditSuccess: "Flow wurde bearbeitet",
        flowEditFailed: "Bearbeitung des Flows gescheitert"
      },
      detail: {
        tabSelect: "Tab wählen",
        createJdmSelectTabError: "Bitte wählen Sie eine Sektion (Eingabe/Transformation/Ausgabe), um ein JDM hinzuzufügen",
        start: "Flow starten",
        end: "Flow beenden",
        renameFlow: "Flow umbenennen",
        configuration: {
          title: "Flow Konfiguration",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          button: "Konfiguration aktualisieren",
          updateSuccess: "Flow-Konfiguration aktualisiert!",
          updateFail: "Aktualisierung der Flow-Konfiguration gescheitert",
          updateFailJsonSyntax: "Fehler bei der JSON-Verarbeitung. Bitte überprüfen Sie Ihre Syntax.",
        },
        input: {
          title: "Eingabe",
          empty: "Es gibt noch keine Eingabe-Dispatcher Mappings"
        },
        transformation: {
          title: "Transformation",
          empty: "Es gibt noch keine Transformatios-Job Dispatcher Mappings"
        },
        output: {
          title: "Ausgabe",
          empty: "Es gibt noch keine Ausgabe-Job Dispatcher Mappings"
        },
        addJdm: "Job Dispatcher Mapping hinzufügen",
        addJdmShort: "Hinzufügen",
        addJdmDisabled: "Bitte wählen Sie einen Tab, um ein JDM hinzuzufügen.",
        export: "Flow exportieren",
        modal: {
          moduleConfig: "Modul Konfiguration",
          dispatchCondition: "Dispatch Condition",
          addJdm: "Job Dispatcher Mapping hinzufügen",
          editJdm: "Job Dispatcher Mapping anpassen",
          deleteJdm: "Job Dispatcher Mapping löschen",
          deleteJdmConfirm: "Sind Sie sicher, dass sie  Job Dispatcher Mapping #{jdmId}: '{jdmName}' löschen wollen?",
          editItem: "Editieren",
          deleteItem: "Löschen",
          chooseProcessStep: "-- Prozessschritt wählen --",
          chooseFQCN: "-- FQCN wählen --",
          noFQCN: "Keine Ergebnisse",
          invalidJson: "Fehler bei der JSON-Verarbeitung. Bitte überprüfen Sie Ihre Syntax.",
          jdmCreationSuccess: "Job Dispatcher Mapping erstellt",
          jdmCreationFailed: "Fehler bei Erstellung des Job Dispatcher Mappings",
          jdmEditSuccess: "Job Dispatcher Mapping bearbeitet",
          jdmEditFailed: "Fehler bei der Bearbeitung des Job Dispatcher Mappings",
          jdmDeletionSuccess: "Job Dispatcher Mapping gelöscht",
          jdmDeletionFailed: "Fehler bei Löschung des Job Dispatcher Mappings"
        },
        jdmToggleEnabled: 'JDM aktiviert',
        jdmToggleDisabled: 'JDM deaktiviert',
        jdmToggleEnabledFailure: 'JDM-Aktivierung fehlgeschlagen',
        jdmToggleDisabledFailure: 'JDM-Deaktivierung fehlgeschlagen'
      }
    },
    executions: {
      headline: "Flow Ausführungen",
      desc: "Übersicht über Ihre Flow Ausführungen",
      noExecutions: "Es gibt noch keine Flow Ausführungen. Erstellen Sie sie, indem Sie einen Flow ausführen.",
      noExecutionsButton: "Zur Flow Übersicht",
      itemsPerPage: "Items je Seite",
      createdAt: "Erstellt",
      updatedAt: "Aktualisiert",
      flow: "Flow",
      processStep: "Prozessschritt",
      duration: "Dauer",
      gotoDetail: "Zur Detailseite",
      thLabel: {
        id: "ID",
        active: "Aktiv",
        success: "Status",
        updatedAt: "Geupdated",
        processStep: "Prozessschritt",
        processStepShort: "Schritt",
        name: "Flow",
        duration: "Dauer",
        createdAt: "Erstellt",
        time: "Geupdated",
        link: "Link"
      },
      filter: {
        headline: "Filter",
        stateFilter: "Status Filter",
        select: "Flow Auswahl",
        createdDateFrom: "Erstellt nach",
        createdDateTo: "Erstellt vor",
        statusActive: "Läuft",
        statusSuccess: "Erfolgreich",
        statusFail: "Gescheitert",
        clearStatus: "Status filter zurücksetzen",
        noItems: "Keine Flow Ausführungen passen zu Ihren Filtereinstellungen",
        reset: "Alle Filter zurücksetzen"
      },
      detail: {
        filter: "Suche",
        reload: "Neu laden",
        expandAll: "Alles anzeigen",
        collapseAll: "Alles schließen",
        showPending: "Ausstehende einblenden",
        hidePending: "Ausstehende ausblenden",
        showCompleted: "Vollständige einblenden",
        hideCompleted: "Vollständige ausblenden",
        toggleNodeHeaderProgressDisplay: "Laufender Eltern-Fortschritt",
        allFiltered: "Alle Ergebnisse entsprechen Ihrem Filter",
        noResults: "Keine passenden Ergebnisse gefunden",
        workerNotStarted: "Der Flow wurde erstellt, aber es hat noch kein Worker mit der Arbeit begonnen. Dies kann auf ein technisches Problem hindeuten, da dieser Flow vor mehr als 5 Minuten erstellt wurde.",
        workerNotStartedYet: "Der Flow wurde erstellt, aber es hat noch kein Worker mit der Arbeit begonnen. Entweder sind alle Worker mit anderen Abläufen beschäftigt, oder es sind Worker angeschlossen.",
        tabs: {
          list: "Liste",
          logs: "Logs"
        },
        autoRefresh: {
          disable: "Keine automatische Aktualisierung",
          enable: "Aktualisiere automatisch nach {seconds} Sekunden",
          autoRefreshActivatedInfo: "Autoaktualisierung aktiviert. Aktualisiere alle {seconds} Sekunden"
        },
        refresh: "Aktualisieren",
        notFound: "Flow Execution nicht gefunden.",
        alreadyFinished: "Flow Execution bereits abgeschlossen"
      }
    },
    status: {
      running: "Läuft",
      runningTooltip: "Der Flow verarbeitet gerade...",
      positive: "Erfolgreich",
      positiveTooltip: "Flow wurde erfolgreich ausgeführt",
      negative: "Gescheitert",
      negativeTooltip: "Ausführung des Flows ist gescheitert",
      none: "Nie ausgeführt",
      noneTooltip: "Dieser Flow wurde noch nie gestartet"
    },
    jumpToExecution: "Letzte Ausführung",
    openFlow: "Flow öffnen",
    stopExecution: "Ende '{flowName}' Flow Ausführung",
    flowStartedSuccess: "Flow erfolgreich gestartet",
    noActiveJdmError: "Kein aktives JDM",
    createdAt: "Erstellt: {time}",
    updatedAt: "Geupdated: {time}",
    duration: "Dauer: {time}",
    flowImportSuccess: "\'{flowName}\' erfolgreich importiert",
    flowImportFail: "Import von \'{flowName}\' gescheitert"
  },
  cacheUsage: {
    noData: 'Keine Daten vorhanden',
    overview: {
      headline: 'Cache-Nutzung',
      desc: 'Flow-Ausführung',
      showKeys: 'Cache-Schlüssel anzeigen',
      goToDetail: "Zur Detailseite",
      itemsPerPage: "Items je Seite",
      overallTotalSize: 'Gesamtgröße',
      tableColumns: {
        flowExecutionId: 'Flow-Ausführungs-ID',
        flowName: 'Flow',
        totalSize: 'Gesamtgröße'
      }
    },
    detail: {
      headline: 'Cache-Nutzung',
      overallTotalSize: 'Gesamtgröße',
      search: 'Suche',
      refresh: 'Aktualisieren',
      autoRefresh: {
        disable: "Keine Autoaktualisierung",
        enable: "Autoaktualisierung alle {seconds} Sekunden",
        autoRefreshActivatedInfo: "Autoaktualisierung aktiv. Aktualisiert alle {seconds} Sekunden"
      },
      tableColumns: {
        cacheKey: 'Schlüssel',
        cacheSize: 'Größe'
      }
    }
  },
  entities: {
    title: "Entität: {entity}",
    titleEmpty: "Entitäten",
    selectFromList: "Bitte wählen Sie einen Eintrag aus der Liste",
    noEntities: "Keine Entitäten verfügbar",
    desc: "Sie können Entitäten mittels Filter-Query filtern",
    entityListHeadline: "Ihre Entitäten",
    maximize: "Öffne Reihe in erweitertem Editor",
    buttonExpand: "Öffnen",
    buttonCollapse: "Schließen",
    clickToExpand: "Doppelklick zum öffnen",
    totalItems: "Entitäten insgesamt: {totalItems}",
    refresh: "Entäten aktualisieren",
    delete: "Lösche {entity} Entitäten",
    refreshDescription: "Achtung: Ungespeicherte Änderungen gehen verloren!",
    refreshButton: "Aktualisieren",
    filterActiveNotice: "Filter aktiv",
    id: "Identifier",
    mongoId: "Mongo ID",
    date: "Datum",
    modal: {
      deleteRow: "Eintrag löschen",
      deleteRowConfirm: "Sind Sie sicher, dass Sie Eintrag {rowId} löschen wollen?",
      deleteRowSuccess: "Eintrag {rowId} gelöscht",
      deleteRowError: "Konnte Eintrag {rowId} nicht löschen",
      deleteEntity: "Entitäten löschen",
      deleteEntityConfirm: "Sind Sie sicher, dass sie alle Entitäten von {entityList} löschen wollen?",
      deleteEntitySuccess: "Alle Entitäten von {entitiyList} gelöscht",
      deleteEntityError: "Entitäten von {entitiyList} konnten nicht gelöscht werden",
      deleteEntityClearButton: "Alle Einträge löschen"
    },
    submitQuery: "Filter Query ausführen",
    clearFilter: "Filter zurücksetzen",
    clearQuery: "Query löschen",
    presetFieldFilled: "Das Abfragefeld ist bereits ausgefüllt",
    filterNoEntities: "Keine Entitäten passen zu Ihrem Filter",
    menu: {
      noEntityLists: "Es gibt noch keine Entitäten",
      reload: "Neu laden"
    },
    preselectFilter: {
      byId: "ID",
      byIdentifier: "Identifier",
      sinceDate: "Seit...",
      betweenDates: "Zwischen..."
    },
    editRowSuccess: "Reihe \'{rowid}\' wurde aktualisiert!",
    editRowFail: "Reihe \'{rowid}\' konnte nicht aktualisiert werden",
  },
  logs: {
    headline: "Logs",
    desc: "{nLogs} Logs",
    download: {
      downloadOverview: "Herunterladen...",
      downloadAll: "Alles herunterladen",
      downloadFiltered: "Gefilterte Logs herunterladen",
      downloadPage: "Logs der aktuellen Seite herunterladen"
    },
    timestamp: "Zeitpunkt",
    level: "Level",
    message: "Nachricht",
    action: "Aktion",
    copiedToClipboard: "Log Info in Zwischenablage kopiert",
    type: {
      all: "All",
      emergency: "Notfall",
      alert: "Alarm",
      critical: "Kritisch",
      error: "Fehler",
      warning: "Warnung",
      notice: "Bemerkung",
      info: "Info",
      debug: "Debug"
    },
    searchPlaceholder: "Suche",
    emptyList: 'Keine Logs vorhanden',
    sortedAsc: 'Chronologisch aufsteigend sortiert',
    sortAsc: 'Chronologisch aufsteigend',
    sortedDesc: 'Chronologisch absteigend sortiert',
    sortDesc: 'Chronologisch absteigend',
    messageFilter: 'Suche nach Text in Log Nachricht',
    activeLevels: 'Aktive Levels',
    refresh: "Aktualisieren",
    autoRefresh: {
      disable: "Keine Autoaktualisierung",
      enable: "Autoaktualisierung alle {seconds} Sekunden",
      autoRefreshActivatedInfo: "Autoaktualisierung aktiv. Aktualisiert alle {seconds} Sekunden"
    },
    noLevelsDisabled: "Alle Level aktiv",
    singleLevelDisabled: "1 Level deaktiviert",
    levelsDisabled: "{x} Level deaktiviert",
    allLevelsDisabled: "Alle Level deaktiviert",
    tableActions: {
      copyEditorContent: 'Lognachricht kopieren',
      viewLogInfo: 'Protokollinformationen anzeigen'
    },
    logContext: "Log Kontext"
  },
  user: {
    headline: "Nutzer",
    desc: "",
    addUser: "Nutzer hinzufügen",
    createUser: "Nutzer anlegen",
    createUserSuccess: "Nutzer erstellt",
    createUserFail: "Nutzererstellung gescheitert",
    updateUser: "Nutzer anpassen",
    updateUserSuccess: "Nutzer aktualisiert",
    updateUserFail: "Aktualisierung des Nutzers gescheitert",
    deleteUser: "Nutzer löschen",
    deleteUserConfirm: "Sind Sie sicher, dass Sie folgenden User löschen möchten? : ",
    deleteUserSuccess: "Nutzer gelöscht",
    deleteUserFail: "Löschen des Nutzers gescheitert",
    itemsPerPage: "Nutzer je Seite",
    form: {
      id: "ID",
      name: "Name",
      mail: "Mail",
      action: "Aktion",
      role: "Administrator",
      sendEmailNotification: 'E-Mail-Benachrichtigung senden',
      password: "Passwort",
      overwritePassword: "Passwort überschreiben? Leer lassen, wenn aktuelles Passwort beibehalten werden soll",
      passwordRepeat: "Passwort wiederholen",
      overwritePasswordRepeat: "Passwortüberschreibung wiederholen",
      rules: {
        name: {
          empty: "Name benötigt"
        },
        mail: {
          empty: "E-Mail benötigt",
          wrongRegex: "Bitte geben Sie eine valide Mailadresse ein"
        },
        password: {
          empty: "Password benötigt",
          tooShort: "Password muss mindestens {minLength} Zeichen lang sein",
          repeatDoesNotMatch: "Das wiederholte Passwort entspricht nicht dem vorherigen"
        },
        roleSelect: {
          empty: "Bitte weisen Sie dem Nutzer eine Rolle zu"
        }
      }
    },
    alreadyExists: "Dieser Nutzer existiert bereits",
    activeUsers: "Aktive Nutzer",
    inactiveUsers: "Inaktive Nutzer"
  },
  failedMessages: {
    headline: "Gescheiterte Queue-Nachrichten",
    desc: "",
    searchString: "Nachricht / Flow Ausführungs-ID",
    itemsPerPage: "Nachrichten je Seite",
    label: {
      flowExecutionId: "Flow Ausführungs-ID",
      message: "Nachricht",
      createdAt: "Erstellt",
      file: "Datei",
    },
    modal: {
      title: "Gescheiterte Queue-Nachricht auf",
      jumpToExecution: "Zur Flow-Ausführung"
    },
    emptyList: 'Es gibt keine gescheiterten Queue-Nachrichten'
  },
  cronjob: {
    headline: "Cronjobs",
    desc: "",
    addCronjob: "Cronjob hinzufügen",
    editCronjob: "Cronjob anpassen",
    createCronjob: "Cronjob anlegen",
    createCronjobSuccess: "Cronjob erstellt",
    createCronjobFail: "Cronjoberstellung gescheitert",
    updateCronjob: "Cronjob aktualisieren",
    updateCronjobSuccess: "Cronjob editiert",
    updateCronjobFail: "Anpassung des Cronjobs gescheitert",
    deleteCronjob: "Cronjob löschen",
    deleteCronjobConfirm: "Sind Sie sicher, dass Sie den Cronjob mit folgender ID löschen möchten? : ",
    deleteCronjobSuccess: "Cronjob gelöscht",
    deleteCronjobFail: "Löschung des Cronjobs gescheitert",
    emptyList: "Es gibt noch keine Cronjobs",
    noFlows: "Keine Flows verfügbar",
    itemsPerPage: "Cronjobs je Seite",
    invalidExpression: "Ungültige Eingabe",
    form: {
      flowId: "Flow ID",
      expression: "Expression",
      active: "Aktive",
      activeState: "Cron aktiv",
      rules: {
        empty: "Pflicht"
      }
    },
    cronjobAlreadyExists: "Cronjob existiert bereits"
  },
  browser: {
    headline: "Browser",
    desc: "Hier können Sie virtuelle Dateien und Dateisysteme verwalten. Sie können lokale Ordner oder Remote-Dienste hinzufügen und alle CRUD-Operationen (Erstellen, Lesen, Aktualisieren, Löschen) für Dateien in der Benutzeroberfläche durchführen.",
    emptyFolder: "Ordner ist leer",
    filterNoMatch: "Keine Dateien oder Ordner treffen auf Ihren Filter zu",
    backToParentFolder: "Zum vorherigen Ordner",
    nameInput: {
      newFolder: "Neuer Ordnername...",
      changeName: "Neuer Dateiname...",
      moveFile: "Pfad..."
    },
    rename: "Umbenennen",
    move: "Verschieben",
    cut: "Ausschneiden",
    copy: "Kopieren",
    delete: "Löschen",
    paste: "Einfügen",
    download: "Herunterladen",
    addFilesystem: "Dateisystem hinzufügen",
    addFilesystemDesc: "Virtuelle Dateisysteme können lokale Ordner oder Ferndienste (wie FTP oder S3) sein",
    editFilesystem: "Dateisystem bearbeiten",
    deleteFilesystem: "Dateisystem löschen",
    deleteFilesystemDesc: "Sind Sie sicher, dass Sie '{filesystem}' löschen möchten?",
    editFilesDesc: "Die Mehrfachauswahl ist aktiviert und funktioniert durch Anklicken mehrerer Elemente mit der linken Maustaste",
    deleteFileDesc: "Sind Sie sicher, dass Sie '{file}' löschen möchten?",
    loadFilesystemError: "Dateisystem konnte nicht geladen werden",
    addFolder: "Neuer Ordner",
    addFile: "Neue Datei",
    copyFile: "Datei kopieren",
    copyInsideItselfError: "Ein Ordner kann nicht in sich selbst kopiert werden! Bitte passen Sie Ihre Auswahl an",
    folderDownloadNotPossible: "Nur Dateien können heruntergeladen werden. Bitte passen Sie Ihre Auswahl an.",
    downloadFile: "Herunterladen",
    downloadRequestBlockedByClient: "Dateidownload fehlgeschlagen. Entweder ist die Datei defekt oder Ihr Browser blockiert den Download. Bitte überprüfen Sie Ihre Einstellungen und versuchen Sie es erneut.",
    filter: "Filter",
    deleteFile: "Datei löschen",
    newFile: {
      headline: "Neue Datei",
      nameLabel: "Dateiname (inkl. Dateierweiterung)",
      namePlaceholder: "Beispiel.txt",
      nameRequired: "Dateiname benötigt",
      fileContentHeadline: "Dateiinhalt",
      urlLabel: "URL",
      urlPlaceholder: "Beispiel: https://www.synqup.io/wp-content/themes/synqup/img/logo/logo.svg",
      urlRequired: "URL benötigt",
      tab: {
        create: "Datei erstellen",
        uploadFromUrl: "Datei von URL hochladen"
      }
    },
    modal: {
      namePlaceholder: "Name",
      nameRequired: "Dateisystemname wird benötigt",
      jsonConfigHeadline: "JSON-Konfiguration",
      createFilesystem: "Erstellen",
      editFilesystem: "Bearbeiten",
      deleteFilesystem: "Löschen",
      createFilesystemSuccess: "Dateisystem erstellt",
      editFilesystemSuccess: "Konfiguration bearbeitet",
      deleteFilesystemSuccess: "Dateisystem gelöscht",
      editFile: "Bearbeiten",
      addFileSuccess: "Datei hinzugefügt",
      editFileSuccess: "Datei bearbeitet",
      addFolderSuccess: "Ordner hinzugefügt",
      deleteFileSuccess: "Datei gelöscht",
      renameFileSuccess: "Datei umbenannt",
      moveFileSuccess: "Datei verschoben",
      copyFileSuccess: "Datei kopiert",
      addFileError: "Dateierstellung gescheitert",
      addFolderError: "Ordnererstellung gescheitert",
      renameError: "Umbenennen gescheitert",
      copyError: "Kopieren gescheitert",
      moveError: "Verschieben gescheitert",
      deleteError: "Löschen gescheitert",
      editError: "Bearbeitung gescheitert",
      forbiddenChar: "Unzulässiges Zeichen"
    },
    fileContent: {
      size: "Größe",
      date: "Erstelldatum",
      visibility: "Sichtbarkeit",
      content: "Dateiinhalt"
    }
  },
  preferences: {
    headline: "Einstellungen",
    desc: "",
    avatarSelect: "Nutzerbild",
    avatarHint: "Erlaubte Dateiformate: png, jp(e)g, webp",
    languageSelect: "Sprache",
    locale: {
      "en-US": "Englisch",
      "de-DE": "Deutsch"
    },
    themeSelect: "Theme",
    theme: {
      auto: "Automatisch",
      light: "Hell",
      dark: "Dunkel"
    },
    enableDevSettings: 'Entwickleroptionen aktivieren',
    enableDevErrorDetails: {
      moreDetails: 'Mehr Details in allgemeinen Fehlermeldungen (alerts)',
    },
    enableEmailNotification: 'E-Mail-Benachrichtigung aktivieren',
    emailNotifications: {
      flowFailed: 'Flow fehlgeschlagen',
      messageFailed: 'Message fehlgeschlagen',
      diskFull: 'Speicherplatz voll'
    },
    preferenceUpdateFailed: 'Einstellung konnte nicht aktualisiert werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
    preferenceUpdateSuccess: 'Einstellung aktualisiert',
  },
  validation: {
    headline: "Validierungs-Infoliste",
    desc: "",
    loadingData: "Lade Daten...",
    noData: "Keine Daten",
    paginationRowsPerPage: "Einträge je Seite",
    documentType: "Dokument-Typ",
    search: "Dokumenten-ID",
    messageSearch: "Nachricht",
    entityType: "Entitäten-Typ",
    severity: "Schweregrad",
    severityLevels: 'Schweregrade',
    validationStats: "Validierungsstatistiken",
    table: {
      index: "#",
      severityLevel: "Schweregrad",
      document: "Dokument",
      message: "Nachricht"
    },
    noDataOrAllFiltered: "Keine Daten verfügbar, oder alle gefiltert",
    severityLevelTypes: {
      info: 'Info',
      alert: 'Alarm',
      warning: 'Warnung',
      danger: 'Kritisch'
    },
    severityChartType: "Schweregrad Typ",
    pieChart: "Kuchendiagramm",
    barChart: "Balkendiagramm",
    flowSeverities: "Flow Schweregrad Gruppe",
    alertEntities: "Alarm Entitäten",
    entitiesWithAlerts: 'Entitäten mit Alarm-Validierungen',
    entitiesWithoutAlerts: 'Entitäten ohne Alarm-Validierungen',
    commonMessages: "Häufigste Validations-Nachrichten",
    mostSeenEntities: "Entitäten mit den meisten Validations-Nachrichten",
  },
  configSnippets: {
    title: "Konfigurations-Snippets",
    newSnippet: "Neues Snippet",
    editSnippet: "Snippet bearbeiten",
    viewSnippet: "Snippet ansehen",
    deleteSnippet: "Snippet löschen",
    deleteSnippetConfirm: "Sind Sie sicher, dass Sie diesen Konfigurations Snippet entfernen möchten? Vergewissern Sie sich, dass dieses Snippet in keiner der bestehenden Konfigurationen oder anderen Snippets verwendet wird, da es zu ernsthaften Problemen führen kann.",
    processing: "Verarbeite...",
    creationSuccess: "Snippet erfolgreich erstellt.",
    creationFail: "Beim Erstellen eines neuen Snippets ist etwas schief gelaufen. Bitte aktualisieren Sie und versuchen Sie es erneut.",
    updateSuccess: "Snippet erfolgreich aktualisiert",
    updateFail: "Beim Aktualisieren eines neuen Snippets ist etwas schief gelaufen. Bitte aktualisieren Sie und versuchen Sie es erneut.",
    notFound: "Snippet nicht gefunden",
    required: "Feld benötigt",
    maxLengthS: "Character length should not be greater than 255",
    maxLengthM: "Die Zeichenlänge sollte nicht größer als 500 sein",
    snippetKey: "Snippet-Schlüssel",
    snippetType: "Snippet Typ",
    description: "Beschreibung",
    previewTitle: "{type}-Snippet Preview",
    type: {
      config: "Konfiguration",
      template: "Template"
    }
  },
  error: {
    404: {
      title: "404",
      desc: "Diese Seite ist nicht (mehr) verfügbar...",
    },
    backToIndex: "Zurück zum Dashboard",
    backToPrevious: "Zurück zu vorheriger Seite",
    sessionExpired: "Huch! Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein."
  },
  ...jstDeDE,
  ...commonDeDE
}
