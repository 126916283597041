<script>
import {debounce, useQuasar} from 'quasar'
  import { defineComponent, ref } from 'vue'
  import { mapActions, mapState } from 'vuex'
  import { preferenceService } from 'src/services'
  import * as PREFERENCES from 'src/meta/preferences'
  import { updatePrefInLocalStorage, setDarkMode } from 'src/utils'

  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import PreferenceItem from 'pages/Preferences/Components/PreferenceItem.vue'
  import LocalePicker from 'pages/Preferences/Components/LocalePicker.vue'
  import ThemePicker from 'pages/Preferences/Components/ThemePicker.vue'

  export default defineComponent({
    name: 'Preferences',

    components: {
      ThemePicker,
      LocalePicker,
      PreferenceItem,
      LoadingIndicator
    },
    setup () {
      const $q = useQuasar();
    },
    data () {
      return {
        PREFERENCES,
        cardLoaders: {
          enableEmailNotification: false,
          locale: false,
          theme: false,
          flowFailedEmailNotification: false,
          messageFailedEmailNotification: false,
          diskFullEmailNotification: false
        },
        avatarModel: ref(null),
        preferenceForm: {
          theme: null,
          locale: null,
          enableEmailNotification: false,
          flowFailedEmailNotification: false,
          messageFailedEmailNotification: false,
          diskFullEmailNotification: false
        }
      }
    },

    computed: {
      ...mapState('preferences', ['userPreferences'])
    },

    methods: {
      ...mapActions('preferences', ['updatePrefInState']),

      updatePreference: debounce(async function (name, value) {
        if(name === this.PREFERENCES.PREF_LOCALE) this.$q.lang.isoName = value;
        this.$store.dispatch('preferences/startUpdate');

        const payload = [{
          "preferenceKey": name,
          "preference": value
        }]

        try {
          this.cardLoaders[name] = true

          await preferenceService.updatePreference(payload)

          this.updatePrefInState({
            key: name,
            value: this.reCastValue(value)
          })

          updatePrefInLocalStorage(name, value)

          this.$q.notify({
            message: this.$t('preferences.preferenceUpdateSuccess'),
            color: 'positive'
          })

          this.afterEffect(name, value)
        } catch (error) {
          console.error(error);
          this.$q.notify({
            message: this.$t('preferences.preferenceUpdateFailed'),
            color: 'negative'
          })
        } finally {
          this.cardLoaders[name] = false
        }
      }, 200),

      reCastValue(value) {
        if (typeof value === 'number') {
          return !!Number(value)
        }

        return value
      },

      afterEffect(name, value) {
        if (name === 'theme') setDarkMode(value)
      }
    },

    mounted () {
      this.preferenceForm.theme = this.userPreferences.theme || 'auto'
      this.$i18n.locale = this.$q.lang.isoName = this.userPreferences.locale || 'en-US'
      this.preferenceForm.enableEmailNotification = !!Number(this.userPreferences.enableEmailNotification)
      this.preferenceForm.flowFailedEmailNotification = !!Number(this.userPreferences.flowFailedEmailNotification)
      this.preferenceForm.messageFailedEmailNotification = !!Number(this.userPreferences.messageFailedEmailNotification)
      this.preferenceForm.diskFullEmailNotification = !!Number(this.userPreferences.diskFullEmailNotification)
    }
  })

</script>

<template>
  <q-page class="block relative-position">
    <div class="q-pa-md">
      <h1 class="q-mb-lg">{{ $t('preferences.headline') }}</h1>

      <div class="app-preferences row">
        <preference-item
          :label="$t('preferences.languageSelect')"
          :loading="cardLoaders.locale"
          class="col-12 col-sm-6"
        >
          <locale-picker
            v-model="$i18n.locale"
            @update:model-value="(value) => updatePreference(PREFERENCES.PREF_LOCALE, value)"
            data-cy="selectLangPref"
          />
        </preference-item>

        <preference-item
          :label="$t('preferences.themeSelect')"
          :loading="cardLoaders.theme"
          class="col-12 col-sm-6"
        >
          <theme-picker
            v-model="preferenceForm.theme"
            @update:model-value="(value) => updatePreference(PREFERENCES.PREF_THEME, value)"
            data-cy="selectThemePref"
          />
        </preference-item>
      </div>

      <div class="app-preferences row">
        <preference-item :loading="cardLoaders.enableEmailNotification">
          <template #header>
            <q-toggle
              v-model="preferenceForm.enableEmailNotification"
              :label="$t('preferences.enableEmailNotification')"
              @update:model-value="(value) => updatePreference(PREFERENCES.PREF_ENABLE_EMAIL_NOTIFICATION, value)"
              data-cy="setNotifyOnErrorPref"
            />
          </template>

          <q-slide-transition>
            <div
              v-show="preferenceForm.enableEmailNotification"
              class="q-ma-sm q-mb-md"
            >
              <q-card
                bordered
                flat
                class="q-mb-md"
              >
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.flowFailedEmailNotification"
                    :disable="cardLoaders.flowFailedEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => updatePreference(PREFERENCES.PREF_FLOW_FAILED_EMAIL_NOTIFICATION, value)"
                    data-cy="setFlowFailedPref"
                  >
                    {{ $t('preferences.emailNotifications.flowFailed') }}
                  </q-checkbox>
                </q-card-section>

                <q-inner-loading
                  :showing="cardLoaders.flowFailedEmailNotification"
                  color="primary"
                  size="2em"
                />
              </q-card>

              <q-card
                bordered
                flat
                class="q-my-md"
              >
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.messageFailedEmailNotification"
                    :disable="cardLoaders.messageFailedEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => updatePreference(PREFERENCES.PREF_MESSAGE_FAILED_EMAIL_NOTIFICATION, value)"
                    data-cy="setMessageFailedPref"
                  >
                    {{ $t('preferences.emailNotifications.messageFailed') }}
                  </q-checkbox>
                </q-card-section>

                <q-inner-loading
                  :showing="cardLoaders.messageFailedEmailNotification"
                  color="primary"
                  size="2em"
                />
              </q-card>

              <q-card
                bordered
                flat
                class="q-my-md"
              >
                <q-card-section>
                  <q-checkbox
                    v-model="preferenceForm.diskFullEmailNotification"
                    :disable="cardLoaders.diskFullEmailNotification"
                    class="full-width"
                    @update:model-value="(value) => updatePreference(PREFERENCES.PREF_DISK_FULL_EMAIL_NOTIFICATION, value)"
                    data-cy="setDiskFullPref"
                  >
                    {{ $t('preferences.emailNotifications.diskFull') }}
                  </q-checkbox>
                </q-card-section>

                <q-inner-loading
                  :showing="cardLoaders.diskFullEmailNotification"
                  color="primary"
                  size="2em"
                />
              </q-card>
            </div>
          </q-slide-transition>
        </preference-item>
      </div>
    </div>
  </q-page>
</template>
